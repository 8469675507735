/**
 * notifications.js - handles notifications (messages & alerts)
 */

    let notifications = {

        /**
         * Loads (polls) alerts
         * Calls inapp-datasets to retrieve unread messages and alerts
         * from inapp_alerts dataset table
         * The route is polled every 5 seconds.
         * The function 'update' is then called.
         */
        load: function () {

            notifications.getAlerts();

            setInterval(function () {
                notifications.getAlerts();
            }, 5000);       // poll every 5 seconds

        },


        getAlerts: function() {
            notifications.ajaxCall('/inapp/alerts/get-alerts').done(function(response) {
                notifications.update(response);
                return response.success === 1 ? true : false;
            }).fail(function(error) {
                return false;
            });
        },

        /**
        * Post ajax call to query for data
        * @param {string} endpoint string containing the URL to which the request is sent.
        * @param {*} data data to be sent to the server.
        * @returns Deferred object response
        */
        ajaxCall: function(endpoint) {

            return $.ajax({
                global: false,
                url: endpoint,
                type: 'GET',
                headers: {},
                dataType: "json",
                contentType: "application/json",
                timeout: 10000,  // set timeout to 10 seconds
            });
        },

        /**
         * Updates alert-icon
         * If there are any unread messages/alerts present then the alert icon
         * (bell) is displayed together with the number of unread alerts.
         * If there are no unread alerts the alert icon is hidden.
         *
         * @param {*} data unread alerts/messages data
         */
        update: function (data) {

            let counter = data.length;

            let notificationsElement = document.getElementById('alert-icon');

             // Hides the notification badge if there are 0 alerts
            if (counter == 0) {
                $(notificationsElement).hide();
            } else {
                $('span', '#alert-icon').text(counter);
                $(notificationsElement).show();
            }

        }

    }

    notifications.load();
